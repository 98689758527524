<template>
    <div class="centro">
      <v-card
        outlined
        id="cartao"
        class="elevation-10 mb-2"
      >
        <v-card-title class="mb-10">
          <svg-icon height="50px" width="50px" type="mdi" :path="icone"></svg-icon>
          <h3 v-if="this.$route.fullPath == '/clientes/new'">Cadastro de Clientes (Inclusão)</h3>
          <h3 v-else>Cadastro de Clientes (Alteração)</h3>
        </v-card-title>
        <form id="formulario">
          <v-row>
            <v-col cols="2">
              <v-text-field 
                id="id" 
                name="id"
                dense
                disabled
                v-model="cliente.id"
                label="ID"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5"></v-col>
            <v-col cols="5" class="imagemargin">
              <v-img
                id="logomarca"
                :src="logomarcabase64" 
                alt="Sem imagem"
                width="200px"
                height="200px"
                @click="onUploadImageClick"
              />
            </v-col>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                id="cpfcnpj" 
                name="cpfcnpj"
                dense
                v-model="cliente.cpfcnpj"
                label="CPF/CNPJ"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                id="nome" 
                name="nome"
                dense
                v-model="cliente.nome"
                label="Nome"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-text-field
                id="logradouro" 
                name="logradouro"
                dense
                v-model="cliente.logradouro"
                label="Logradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                id="numero" 
                name="numero"
                dense
                v-model="cliente.numero"
                label="Número"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                id="complemento" 
                name="complemento"
                dense
                v-model="cliente.complemento"
                label="Complemento"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                dense
                v-model="cliente.id_cidade"
                :items="cidades"
                :item-text="getCidadeText"
                item-value="id"
                label="Cidade"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                v-model="cliente.id_bairro"
                :items="bairros"
                :item-text="getBairroText"
                item-value="id"
                label="Bairro"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                id="telefone" 
                name="telefone"
                dense
                v-model="cliente.telefone"
                label="Telefone"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </form>
        <v-btn class="botoes" color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
        <v-btn class="botoes" color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card>
    </div>
  </template>
  
  <script>
  import svcClientes from "../../services/svcClientes";
  import svcCidades from "../../services/svcCidades";
  import svcBairros from "../../services/svcBairros";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { decriptar } from "../../config/cripto";
  import { mdiOfficeBuilding } from '@mdi/js';
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        icone: mdiOfficeBuilding,
        cliente: {
          id: "",
          cpfcnpj: "",
          nome: "",
          logradouro: "",
          numero: "",
          complemento: "",
          id_cidade: "",
          id_bairro: "",
          telefone: "",
          logomarca: ""
        },
        cidades: [],
        bairros: [],
        logomarca: null,
        logomarcabase64: "",
        imgheight: 0,
        imgwidth: 0
      };
    },
  
    methods: {
      getCidadeText(item) {
        this.lista_bairros(item.id);
        return item.nome + " - " + item.uf;
      },

      lista_cidades() {
        svcCidades
          .listar()
          .then((resp) => {
            this.cidades = resp.data;
          })
          .catch((error) => {
            alert(error.response.data.mensagem);
            localStorage.clear();
            this.$router.push("/login");
          });
      },

      getBairroText(item) {
        return item.nome;
      },

      lista_bairros(id_cidade) {
        svcBairros
          .listar(id_cidade)
          .then((resp) => {
            this.bairros = resp.data;
          })
          .catch((error) => {
            alert(error.response.data.mensagem);
            localStorage.clear();
            this.$router.push("/login");
          });
      },

      pesquisa(id) {
        svcClientes
          .pesquisar(id)
          .then((resp) => {
            this.cliente = resp.data;
            if ((this.cliente.logomarca == null) || (this.cliente.logomarca.trim() == "")) {
              this.logomarcabase64 = require("@/assets/logopadrao.png");
            } else {
              this.logomarcabase64 = process.env.VUE_APP_URL_FILES + "/l/" + this.cliente.logomarca;

            }
            const img = document.getElementById('logomarca'); 
            this.imgwidth = img.clientWidth;
            this.imgheight = img.clientHeight;
          })
          .catch((error) => {
            alert(error.response.data.mensagem);
          });
      },
  
      cancel() {
        this.reposiciona();
      },
  
      save() {
        if (this.$route.fullPath == "/clientes/new") {
          svcClientes
            .incluir(this.cliente)
            .then((resp) => {
              if (resp.status == 200) {
                this.cliente = resp.data;
                svcClientes
                  .uploadimage(this.cliente.cpfcnpj, this.logomarca)
                  .then((resp) => {
                    if (resp.status == 200) {
                      this.cliente.logomarca = resp.data.logomarca;
                    }
                    svcClientes.alterar(this.cliente);
                  })
                  .then(() => {
                    this.reposiciona();
                  });
              } else {
                alert("Erro inserindo novo cliente.");
              }
            })
            .catch((error) => {
              alert(error);
            });
        } else {
          svcClientes
            .uploadimage(this.cliente.cpfcnpj, this.logomarca)
            .then((resp) => {
              if (resp.status == 200) {
                this.cliente.logomarca = resp.data.logomarca;
              }
              svcClientes
                .alterar(this.cliente)
                .then((resp) => {
                  if (resp.status != 200) {
                    alert("Erro alterando dados do cadastro.");
                  }
                })
                .then(() => {
                  this.reposiciona();
                })
                .catch((error) => {
                  alert(error);
                });
            }
          );
        }
      },
  
      reposiciona() {
        this.$router.push("/clientes");
      },

      onUploadImageClick() {
        this.isSelecting = true;
        window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
        );

        this.$refs.uploader.click();
      },

      onFileChanged(e) {
        this.logomarca = e.target.files[0];

        var reader = new FileReader();

        const callback = () => {
          this.logomarcabase64 = reader.result;
        };

        reader.onload = callback;
        reader.readAsDataURL(this.logomarca);
      },

      onDeleteImageClick() {
        // this.logomarca = null;
        // this.logomarcabase64 = null;
        // this.cliente.logomarca = null;      
      },
    },
  
    mounted() {
      this.lista_cidades();
      if (this.$route.fullPath != "/clientes/new") {
        this.pesquisa(decriptar(this.$route.params.id))
      } else {
        this.logomarcabase64 = require("@/assets/logopadrao.png");
        const img = document.getElementById('logomarca'); 
        this.imgwidth = img.clientWidth;
        this.imgheight = img.clientHeight;
      }
    },
  };
  </script>
  
  <style scoped>
  label {
    font-weight: bold;
    margin-bottom: 1px;
    color: #222;
    text-align: left;
  }
  .botoes {
    margin-top: 5px;
  }
  .imagemargin {
    margin-top: -40px;
  }
</style>
  