import { http } from './config'
import { encriptar } from '../config/cripto';

export default {
    listar: (id_cidade) => {
        return http.get(`/bairros/listar/${encriptar(id_cidade)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    incluir: (bairro) => {
        const params = {
            bairro: bairro
        }
        return http.post('/bairros/incluir', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    alterar: (bairro) => {
        const params = {
            bairro: bairro
        }
        return http.put('/bairros/alterar', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    excluir: (id) => {
        return http.delete(`/bairros/excluir/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisar: (id) => {
        return http.get(`/bairros/pesquisar/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisarficha: (id) => {
        return http.get(`/bairros/pesquisarficha/${encriptar(id)}`)
    },
}